<template>
<div>
  <h1>Mi cartera: {{portfolio_name}}</h1>
  <div>
    <Button @_emit_method_button="_openPortfolios" :button_name="button_name_portfolios" :button_key="button_key_portfolios" class="balance-buttons disabled-buttons" />
    <Button @_emit_method_button="_openWaitlist" :button_name="button_name_waitlist" :button_key="button_key_waitlist" class="balance-buttons disabled-buttons" />
    <Button @_emit_method_button="_openPositions" :button_name="button_name_open_positions" :button_key="button_key_open_positions" class="balance-buttons disabled-buttons" />
    <Button @_emit_method_button="_openGeneral" :button_name="button_name_general" :button_key="button_key_general" class="balance-buttons disabled-buttons" />
    <Button :button_name="button_name_historic" :button_key="button_key_historic" class="balance-buttons" />
    <Button @_emit_method_button="_openBalance" :button_name="button_name_balance" :button_key="button_key_balance" class="balance-buttons disabled-buttons" />
  </div>
  <div>
    <table id="tablerisk" class="table table-bordered">
      <tbody>
        <tr class="odd">
          <td>Total: <b>{{total}}€</b></td>
          <td>Total comisión de apertura: <b>{{total_comision_open}}€</b></td>
          <td>Total comisión de cierre: <b>{{total_comision_close}}€</b></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <Table :table_header="table_header" :table_body="table_body" class="table"/>
  </div>
</div>
</template>

<script>
import Button from "@/components/helpers/Button";
import APICaller from "@/components/codeComponents/APICaller.js";
import Table from '@/components/helpers/Table.vue';

export default {
  mixins: [APICaller],  
  name: 'PortfolioBalance',
  props:{
  },
  components: {
    Button,
    Table,
  },
  data() {
    return {
      portfolios: [],
      portfolio_idc: "",
      portfolio_name: "",

      button_name_portfolios: "Carteras",
      button_name_waitlist: "Valores en espera",
      button_name_open_positions: "Posiciones abiertas",
      button_name_general: "General",
      button_name_historic: "Historico",
      button_name_balance: "Balance",
      button_name_variation: "Añadir nueva variación",

      button_key_portfolios: "portfolio-button-key",
      button_key_waitlist: "waitlist-button-key",
      button_key_open_positions: "open-positions-button-key",
      button_key_general: "general-button-key",
      button_key_historic: "historic-button-key",
      button_key_balance: "balance-button-key",
      button_key_variation: "variation-button-key",

      table_header: ['Ticker', 'Acciones', 'Precio de entrada', 'Comisión de entrada', 'Divisa de entrada', 'Precio de salida', 'Comisión de salida', 'Divisa de salida', 'Fecha de entrada', 'fecha de salida', 'fecha de soporte', 'Nº onda', 'Total', 'Notas', 'Opciones'],
      table_body: [],

      daily_balances: "",
      total: 0,
      total_comision_open: 0,
      total_comision_close: 0,
      histories: "",
    };
  },
  watch: {
  },
  created(){
  },
  mounted(){
    this._getPortfolios();
  },
  computed() {       
  },
  methods: {
    _getPortfolios() {
      let success = response => {
        this.portfolios = response.data;
        this._getPortfolioData()
      }

      let url = '/api/v1/portfolio/list'

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('192', 'Error recuperando cartera.');

      this._getAPICall(url, successHandler, failureHandler);
    },
    _getPortfolioData: function(){
      this.portfolio_idc = this.$route.params.idc;
      this.portfolios.forEach((element) =>{
        if(element.idc == this.portfolio_idc){
          this.portfolio_name = element.name;

        }

      });
      this._getHistories();
      
    },  
    _getHistories: function () {
      let success = response => {
        this.histories = this.formatHistories(response.data);
      }

      let url = '/api/v1/portfolio/histories/' + this.portfolio_idc

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('193', 'Error recuperando historias.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _openBalance: function(){
      window.location.href = "/app/portfolio/balance/" + this.portfolio_idc;
    },
    _openPortfolios: function(){
      window.location.href = "/app/portfolio"
    },
    _openGeneral: function(){
      window.location.href = "/app/portfolio/general/" + this.portfolio_idc;
    },
    _openWaitlist: function(){
      window.location.href = "/app/portfolio/wait-list/" + this.portfolio_idc;
    },
    _openPositions: function(){
      window.location.href = "/app/portfolio/open-positions/" + this.portfolio_idc;
    },
  }
}

</script>

<style lang="scss" scoped>
.balance-buttons{
  margin-left: 10px;
  font-size:13px !important;
}
.disabled-buttons{
  background-color: lightgrey;
  color: black;
}
.variation-button{
  margin-top: 50px;
  margin-left: 10px;
  font-size:13px !important;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  background-color: #51a351;
}
.table{
  margin-left: 10px;
}

#tablerisk{
  width: 100%;
  margin-bottom: 14px;
  clear: both;
}

.table{
  width: 100%;
  margin-bottom: 20px;
}

.table-bordered{
  border: 1px solid #ddd;
  border-collapse: separate;
  border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-top: 20px;
}
tr{
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

table tr{
  height: 30px;
}

.odd{
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 0.1em 0.6em;
}

</style>